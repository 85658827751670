body {
  margin: 0;
  height: 100%;
}
html{
  height: 100%;
}
.avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  vertical-align: middle;
}
.rectangular {
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.04);
}
.iframe-hack {
  width: 100%;
  height: 100%;
}
.next-shell-sub-main{
  background-color: #fff;
}
.next-shell-brand .next-shell-header{
  background-color: #0674a7;
}
.next-shell-brand .next-shell-header .dock-trigger, .next-shell-brand .next-shell-header .nav-trigger{
  background-color: #0674a7;
}
.next-shell-brand .next-shell-aside.next-shell-navigation{
  background-color: #0674a7;
}
.next-nav.next-normal .next-nav-item.next-menu-item.next-child-selected{
  background-color: #0674a7;
  color:#fff;
}
.next-nav.next-normal .next-nav-item.next-child-selected{
  color:#fff;
  background-color: #072f3d;
}
.next-nav.next-normal .next-nav-item.next-menu-item.next-selected{
  color:#fff;
  background-color: #072f3d;
}
.next-nav.next-normal .next-menu-item{
  color:#fff;
  background-color: #0674a7;
}
.next-nav.next-normal .next-menu-sub-menu .next-menu-item.next-selected{
  background-color: #072f3d;
  color:#fff;
}
.next-nav.next-normal .next-nav-item.next-menu-item.next-focused, .next-nav.next-normal .next-nav-item.next-menu-item:hover{
  color:#fff;
  background-color: #072f3d;
}
.next-nav.next-ver .next-menu-sub-menu{
  background-color: #515A6E;
}
.next-shell-brand .next-shell-main{
  background-color: #0674a7;
}
.next-shell-brand .next-shell-header{
  border-bottom: 0;
}
.next-nav.next-normal,.next-nav.next-normal .next-nav-item.next-menu-item{
  color:#fff;
}
.next-nav.next-normal .next-nav-item.next-menu-item.next-child-selected{

}
.next-nav.next-normal .next-nav-item.next-menu-item.next-opened{
  background-color: #0674aa;
  color:#fff;
}
.container{
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 0;
}
.my-hoz-menu .next-menu-item {
  width: 160px;
}
.my-hoz-menu .next-menu-item.next-menu-more {
  width: 60px;
}
.overlay-demo {
  width: 300px;
  height: 100px;
  padding: 10px;
  border: 1px solid #999999;
  background: #FFFFFF;
  box-shadow: 2px 2px 20px rgba(0,0,0,0.15);
}

.message-menu {
  position: relative;
  width: 70px;
  //overflow: hidden;
  height: 50px;
  float: left
}

.message-menu .sn-menu-message {
  margin-top: 12px
}

.message-menu .sn-menu-message-mail,.message-menu .sn-menu-message-star {
  margin: 6px 18px
}

.message-menu .sn-menu-message-shape {
  margin: 6px 16px 6px 18px
}

.message-menu i {
  width: 26px;
  height: 26px
}

.message-menu .next-badge .next-badge-scroll-number {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.5);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.5);
  border-radius: 29px
}

.message-menu .next-badge .next-badge-count {
  background: #ff7519;
  height: 20px;
  line-height: 20px;
  padding: 0 6px
}

.message-menu .next-badge-scroll-number-only span {
  height: 20px;
  line-height: 20px
}

.message-menu .message-icon {
  float: right;
  display: block;
  height: 50px;
  line-height: 50px;
  padding: 0 16px 0 22px
}

.message-menu .message-icon .next-badge .next-badge-scroll-number {
  top: 4px;
  border: 2px solid #fff;
  line-height: 18px
}

.message-menu .message-icon .next-badge-scroll-number-only span {
  line-height: 18px
}

.message-menu .icon-medium {
  color: #fff;
  line-height: 50px
}

.message-menu .message-select {
  background: #fff;
  z-index: 9999;
  width: 400px;
  position: fixed;
  top: 58px;
  right: 88px;
  -webkit-box-shadow: 0 3px 7px 0 rgba(0,0,0,.16);
  box-shadow: 0 3px 7px 0 rgba(0,0,0,.16);
  border-radius: 4px 4px 0 0
}

.message-menu .message-select-after {
  content: "";
  position: absolute;
  top: 40px;
  left: -10px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #4d4d4d;
  z-index: 9999;
}

.message-menu .message-select ul.next-menu-content {
  width: 100%
}

.message-menu .message-select .title-menu {
  background: #4d4d4d;
  -webkit-box-shadow: 0 3px 7px 0 rgba(0,0,0,.16);
  box-shadow: 0 3px 7px 0 rgba(0,0,0,.16);
  border-radius: 4px 4px 0 0;
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.message-menu .message-select .title-menu .icon-medium {
  line-height: 20px;
  font-size: 16px
}

.message-menu .message-select .title-menu .icon-medium.icon-email {
  font-size: 14px
}

.message-menu .message-select .title-menu .item-message {
  width: 60px;
  height: 38px;
  line-height: 52px;
  text-align: center
}

.message-menu .message-select .title-menu .item-message .next-badge {
  color: #fff
}

.message-menu .message-select .title-menu .item-message .next-badge .next-badge-count {
  background: #ff7519
}

.message-menu .message-select .title-menu .collection {
  width: 60px;
  height: 38px;
  line-height: 52px;
  text-align: center;
  color: #fff;
  cursor: pointer
}

.message-menu .message-select .title-menu .blank {
  width: calc(100% - 180px)
}

.message-menu .message-select .title-menu .set {
  width: 60px;
  height: 38px;
  line-height: 52px;
  text-align: right;
  text-align: center;
  color: #fff;
  cursor: pointer
}

.message-menu .message-select .title-menu .active {
  background-color: #333;
  z-index: 2
}
// .message-menu .message-select .message-detail {
//   height: 560px;
//   overflow: auto;
//   line-height: 20px;
//   color: #666;
//   padding: 15px
// }
.message-menu .message-select .message-detail {
  // height: 560px;
  overflow: auto;
  line-height: 20px;
  color: #666;
  padding: 15px
}
@media screen and (min-width: 1200px ) { 
  .message-detail {
    height: 470px;
  }
}
@media screen and (min-width: 1527px) { 
   .message-detail {
    height: 560px;
  }
}
.message-menu .message-select .message-detail .title-day {
  text-align: center
}

.message-menu .message-select .message-detail .message-body {
  padding-bottom: 40px
}

.message-menu .message-select .message-detail .msg-title {
  color: #333;
  font-size: 14px
}

.message-menu .message-select .message-detail .msg-title .icon-medium.icon-weiji {
  color: #ff1815
}

.message-menu .message-select .message-detail .send-time {
  position: relative;
  top: -7px;
  width: 40px;
  height: 40px;
  float: right;
  line-height: 50px;
  margin-top: 34px;
}

.message-menu .message-select .message-detail .content {
  padding: 8px 15px;
  cursor: pointer;
  border-bottom: 1px solid #eee
}

.message-menu .message-select .message-detail .content .no-collect {
  display: none
}

.message-menu .message-select .message-detail .content:hover {
  background: #f4f4f4
}

.message-menu .message-select .message-detail .content:hover .no-collect {
  display: block
}

.message-menu .message-select .message-detail .content .icon-medium {
  font-size: 16px
}

.message-menu .message-select .message-detail .content .dot {
  width: 6px;
  height: 6px;
  position: absolute;
  left: -10px;
  top: 6px;
  background: #ff1815;
  border-radius: 4px
}

.message-menu .message-select .message-detail .day-message .next-badge .next-badge-scroll-number {
  top: -2px;
  left: -5px;
  -webkit-box-shadow: none;
  box-shadow: none
}

.message-menu .message-select .message-detail .day-message .icon-medium.icon-xing {
  color: #ff7519
}

.message-menu .message-select .message-detail .day-message .icon-medium {
  color: #fcb30f;
  line-height: 20px
}

.message-menu .message-select .message-detail .day-message .content-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px
}

.message-menu .message-select .message-detail .day-message .collect-icon {
  width: 30px;
  margin-top: 40px;
  margin-right: 10px;
  display: inline-block
}

.message-menu .message-select .message-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: absolute;
  z-index: 11;
  width: 100%;
  height: 40px;
  line-height: 40px;
  left: 0;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #eee;
  padding-right: 15px;
  padding-left: 15px
}

.message-menu .message-select .message-footer .next-badge .next-badge-scroll-number {
  top: -14px
}

.message-menu .message-select .set-tab .next-tabs-medium .next-tabs-tab-inner {
  height: 30px;
  line-height: 30px;
  padding: 0 15px
}

.message-menu .message-select .set-tab .set-title {
  font-size: 14px;
  color: #333;
  font-weight: 700
}

.message-menu .message-select .set-tab .label {
  color: #666
}

.message-menu .message-select .set-tab .next-tabs-nav-scroll {
  text-align: center
}

.message-menu .message-select .set-tab .form-layout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 28px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.message-menu .message-select .set-tab .form-width {
  width: 240px
}

.message-menu .message-select .set-tab .next-switch-medium {
  margin-top: 3px
}

.message-menu .message-select .set-tab .subscribe-title {
  font-size: 14px;
  line-height: 20px;
  padding-right: 10px;
  color: #333;
  font-weight: 700;
  cursor: pointer
}

.message-menu .message-select .set-tab .subscribe-title:hover {
  background: #f4f4f4
}

.message-menu .message-select .set-tab .subscribe-title.close .next-icon-arrow-down {
  display: block
}

.message-menu .message-select .set-tab .subscribe-title.close+.message-set-table,.message-menu .message-select .set-tab .subscribe-title.close .next-icon-arrow-up,.message-menu .message-select .set-tab .subscribe-title.open .next-icon-arrow-down {
  display: none
}

.message-menu .message-select .set-tab .subscribe-title.open+.message-set-table,.message-menu .message-select .set-tab .subscribe-title.open .next-icon-arrow-up {
  display: block
}

.message-menu .message-select .set-tab .subscribe-title .next-icon-medium:before {
  width: 14px;
  font-size: 14px
}

.message-menu .message-select .set-tab .message-set-table {
  border: 1px solid #eee;
  border-bottom: 0
}

.message-menu .message-select .set-tab .message-set-table .next-table-header tr th.next-table-header-node {
  border-right: 0!important
}

.message-menu .message-select .set-tab .message-set-table .next-table th {
  background: #f4f4f4
}

.message-menu .display-f {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.message-menu .mr-10 {
  margin-right: 10px
}

.message-menu .mb-10 {
  margin-bottom: 10px
}

.message-menu .mr-5 {
  margin-right: 5px
}

.message-menu .mt-5 {
  margin-top: 5px
}

.message-menu .mb-20 {
  margin-bottom: 20px
}

.message-menu .mb-15 {
  margin-bottom: 15px
}

.message-menu i {
  display: block
}

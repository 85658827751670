
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  text-decoration:none;
  color: #111;
}
.basic-nav .fusion {
  margin: 8px 20px;
  width: 100px;
  color: #FFFFFF;
  font-size: 20px;
  line-height:45px;
}
.basic-nav .login-in {
  margin: 0 20px;
  color: #FFFFFF;
  line-height: 45px;
}
.basic-nav .next-nav-item {
  width: 120px;
}

.iconLine{
  display: inline-block;
  height: 16px;
  width: 4px;
  background-color: #666;
  vertical-align: middle;
}
.titleIcon{
  margin-left:5px;
  color:#111;
  height: 16px;
  font-size: 16px;
 vertical-align: middle;
  font-weight: 600;
}
.right{
    float: right;
}

.next-btn .next-large{
    border-radius: 4px !important;
    height: 34px !important;
}

.greenBtn{
    background-color:#52C41A !important;
    color:#fff !important;
    border:0 !important;
}
.greenBtn:hover{
    background-color:#60DA24 !important ;
}
.greenBtn:active{
    background-color:#3FB207 !important ;
}

.yellowBtn{
    background-color:#FCB30F !important;
    color:#fff !important;
    border:0 !important;
}
.yellowBtn:hover{
    background-color:#FFBC26 !important;
}
.yellowBtn:active{
    background-color:#EBA300 !important;
}


.next-btn.next-btn-secondary{
    border-color: #C7DFE6;
}
.next-btn.next-btn-secondary:hover{
    background-color: #fff;
    color:#2ECCF9;
    border-color: #2ECCF9;
}
.next-btn.next-btn-secondary:active{
    background-color: #EDF9FD;
    color:#38A7DF;
    border-color: #38A7DF;
}

.handleBtn{
    height: 50px;
    background-color: #EEE;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 0 10px 0 0;
}

.searchBarE{
    height: 50px;
    background-color: #EEE;
    margin-bottom: 12px;
    margin-top: 12px;
}
.searchBarE .next-select-multiple.next-medium .next-input-control{
    height: 26px;
}

.pageContainer{
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
}
.pageContainer>.totalPage{
    display: inline-block;
    font-size: 14px;
    color:#333;
    padding: 15px 20px 4px 0;
    vertical-align: bottom;
}
.bottomPage{
    display: inline-block;
    font-size: 12px;
}

.next-dialog-header {
    padding: 10px 20px;
    color:#333;
    background-color: #E1F4F9;
}
.next-dialog-footer{
    padding:20px 20px 20px 20px;
}
.next-form-item-label{
    color:#666;
}

.next-table td .next-table-cell-wrapper{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.next-table th .next-table-cell-wrapper{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
 .next-dialog-header{
    background-color:#e1f4f9!important;
}
